<template>
  <div class="card">
    <div class="card-content">
      <div class="block">
        <label class="label"> Categoría </label>
        <div v-if="modeEdit" class="select slct is-fullwidth" :class="{ 'is-loading' : loadingCat }">
          <select
            v-model="localCategoryId"
            name="localCategoryId"
            v-validate="'required'"
            data-vv-as="de la categoría"
            @change="$emit('set-value', { name: 'categoryId', value:  localCategoryId })"
          >
            <option selected disabled :value="null">Categoría</option>
            <option v-for="category in categoriesList" :key="category.id" :value="category.id">
              {{ category.name }}
            </option>
          </select>
          <span v-show="vverrors.has('localCategoryId')" class="help is-danger">{{ vverrors.first('localCategoryId') }}</span>
        </div>
        <p v-else class="paragraph"> {{ categoryName }} </p>
      </div>
      <div class="block">
        <label class="label"> Autor </label>
        <input
          v-if="modeEdit"
          type="text"
          class="input"
          name="localAuthor"
          v-model="localAuthor"
          v-validate="'alpha_spaces|required'"
          data-vv-as="del autor"
          @change="$emit('set-value', { name: 'author', value:  localAuthor })"
        >
        <p v-else class="paragraph"> {{ author }} </p>
        <span v-show="vverrors.has('localAuthor')" class="help is-danger">{{ vverrors.first('localAuthor') }}</span>
      </div>
      <div class="block" v-if="!createdMode">
        <label class="label"> Fecha de creación  </label>
        <p class="paragraph"> {{ formatDate(creationDate, 'DD/MM/YYYY') }} </p>
      </div>
      <div class="block is-flex is-align-items-center">
        <label class="label mr-5 mb-0">  Estatus </label>
        <span v-if="modeEdit">
          <input
            id="switchStatus"
            type="checkbox"
            name="switchStatusStatus"
            class="switch is-rounded is-small"
            :checked="localStatus ? 'checked' : ''"
            v-model="localStatus"
            @change="$emit('set-value', { name: 'status', value:  localStatus })"
          >
          <label for="switchStatus"></label>
        </span>
        <p class="paragraph" v-else> {{ localStatus ? 'Activo' : 'Inactivo' }} </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins/formats'

export default {
  name: 'CardGeneralInformatioPublication',
  inject: ['$validator'],
  mixins: [mixins],
  data () {
    return {
      localAuthor: this.author,
      localCategoryId: this.categoryId,
      localStatus: this.status
    }
  },
  props: {
    modeEdit: {
      type: Boolean,
      default: false
    },
    categoryId: {
      default: null
    },
    categoryName: {
      default: null
    },
    author: {
      type: String,
      default: null
    },
    creationDate: {
      type: String,
      default: null
    },
    status: {
      type: Boolean,
      default: false
    },
    createdMode: {
      type: Boolean,
      default: false
    },
    categoriesList: {
      type: Array,
      default: () => []
    },
    loadingCat: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
</style>
